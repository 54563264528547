var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"py-2 px-2 table-view",attrs:{"sm":"12"}},[_c('platon-table',{ref:"table",attrs:{"table-name":_vm.tableName,"table-filters":_vm.filters,"table-classes":"card p-2 mb-0","is-public":_vm.isPublic,"control-buttons":true,"pagination-enabled":true,"show-header":true,"show-filters":true,"navigate-by-keyboard":true},on:{"onMetaLoaded":_vm.onMetaLoaded,"onFiltersChange":_vm.onFiltersChange,"onRowDblClick":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.eventSender.apply(void 0, [ 'onRowDblClick' ].concat( argsArray ))},"onRowClick":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.eventSender.apply(void 0, [ 'onRowClick' ].concat( argsArray ))},"onRowHover":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.eventSender.apply(void 0, [ 'onRowHover' ].concat( argsArray ))},"rowSelected":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.eventSender.apply(void 0, [ 'rowSelected' ].concat( argsArray ))}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }